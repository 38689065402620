import React, { Component, KeyboardEventHandler } from "react";

import CreatableSelect from "react-select/creatable";
import { ActionMeta } from "react-select";
import { colors } from "./theme";

const components = {
  IndicatorSeparator: null,
  DropdownIndicator: () => <div style={{ marginRight: 10 }}>bpm</div>,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface State {
  readonly inputValue: string;
  readonly value: Option;
}

export default class CreatableInputOnly extends Component<
  {
    onChange: (v: string) => void;
    initialValue: string;
  },
  State
> {
  state: State = {
    inputValue: this.props.initialValue,
    value: {} as any,
  };
  handleChange = (value: any, actionMeta: ActionMeta<Option>) => {
    console.group("Value Changed");
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({ value });
  };
  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };
  handleBlur = () => {
    const { inputValue, value } = this.state;

    console.group("Value Added");
    console.log(value, "-->", inputValue);
    console.groupEnd();
    this.setState({
      inputValue: "",
      value: createOption(inputValue),
    });
    this.props.onChange(inputValue);
    // event.preventDefault();
  };
  handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Value Added");
        console.log(value, "-->", inputValue);
        console.groupEnd();
        this.setState({
          inputValue: "",
          value: createOption(inputValue),
        });
        this.props.onChange(inputValue);
        event.preventDefault();
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        menuIsOpen={false}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown as any}
        placeholder="Type something and press enter..."
        value={value}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: colors.buttonColor,
          }),
        }}
      />
    );
  }
}
