import * as globalState from "./globalState";

export function __range__(left: number, right: number, inclusive: boolean) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}

export const setUrl = (mutation: any) => {
  const newParams = {
    noteDivision: globalState.noteDivision.get(),
    bpm: globalState.bpm.get(),
    position: globalState.position.get(),
    scale: globalState.scale.get(),
    note: globalState.note.get(),
    startOn: globalState.startOn.get(),
    ...mutation,
  };

  const params = new URLSearchParams(newParams);
  window.location.hash = params.toString();
};
