import React, { useCallback } from "react";
import * as _ from "lodash";

import { generateNotes, getClearFrets, NotesMap, Tuning } from "./notes";
import { NoteEntry, SCALES } from "./scales";
import GString from "./GString";
import FretDots from "./GuitarComponents/FretDots";
import FretNumbers from "./GuitarComponents/FretNumbers";
import { playClick, playIntroClick } from "./player";

import { isMobile } from "react-device-detect";
import theme, { colors } from "./theme";

import * as globalState from "./globalState";
import { useState } from "@hookstate/core";

import { __range__ } from "./helpers";
import {
  GuitarPos,
  GuitarBoundingBox,
  calculateBoundingBoxForPosition,
  isFretInBox,
  FretType,
  inGuitarPosInBox,
} from "./FretHelpers";
import { BlFret } from "./blFret";
import { NoteNameOptions } from "./NoteNameButtonBar";

type MyProps = {
  tuning: Tuning;
  data?: {
    fretsNum: number;
    stringsNum: number;
  };
  fretWidth: number;
  fretHeight: number;
  displayRef: React.RefObject<HTMLElement>;
};

type Fretboard = Record<number, Record<number, BlFret>>;

export const get_and_color_frets = ({
  stringsNum,
  fretsNum,
  notesMap,
  note,
  boundingBox,
  currentPlayingFret,
  scale,
  noteNameStyle,
}: {
  stringsNum: number;
  fretsNum: number;
  notesMap: NotesMap;
  note: string;
  boundingBox: GuitarBoundingBox | undefined;
  currentPlayingFret: FretType | undefined;
  scale: NoteEntry[];
  noteNameStyle: NoteNameOptions;
}): Fretboard => {
  const frets = getClearFrets(stringsNum, fretsNum, notesMap);

  _.each(frets, (string, sN) => {
    for (let fN in string) {
      const fret = string[fN];

      if (
        inGuitarPosInBox({
          needle: fret.data(),
          haystack: boundingBox,
        })
      ) {
        fret.select();
      }

      if (fret.data().note === note) {
        fret.set_root();
      }

      if (currentPlayingFret) {
        const [_sN, _fN] = Array.from(currentPlayingFret);
        if (
          _sN === (sN as unknown as number) &&
          _fN === (fN as unknown as number)
        ) {
          fret.playStart();
        }
      }

      const needle = fret.data().note;
      if (Array.from(scale.map((n: NoteEntry) => n.name)).includes(needle)) {
        fret.check();

        if (noteNameStyle === "solfege") {
          // Oh my god do I hate this mutable state, I'm sorry
          const scaleIndex = Array.from(
            scale.map((n: NoteEntry) => n.name)
          ).indexOf(needle);
          const solfege = scale[scaleIndex].solfege;
          fret.set_solfege(solfege);
        }
      }

      if (fN === "0") {
        fret.set_open();
      }
    }
  });

  return frets;
};

type Direction = "UP" | "DOWN";

// I have almost no idea what this function is doing anymore
export const get_selected_frets = ({
  startAtRoot,
  frets,
  direction,
  notesMap,
  scale,
}: {
  startAtRoot: boolean;
  frets: Fretboard;
  direction: Direction;
  notesMap: NotesMap;
  scale: NoteEntry[];
}): FretType[] => {
  let sNum;
  let string, fret;
  const ret_tabs: FretType[] = [];

  let strings: [BlFret, number][] = (() => {
    const result: [BlFret, number][] = [];
    _.each(frets, (string: Record<number, BlFret>, sN) => {
      result.push([string as any, sN as unknown as number]);
    });
    return result;
  })();
  if (direction === "DOWN") {
    strings = strings.reverse();
  }

  let shouldInclude = startAtRoot ? false : true;

  for ([string, sNum] of Array.from(strings)) {
    let fNum;
    let frets: [BlFret, number][] = [];
    _.each(string, (fret, fN) => {
      frets.push([fret as any, fN as unknown as number]);
    });

    if (direction === "UP") {
      frets = frets.reverse();
    }

    for ([fret, fNum] of Array.from(frets)) {
      if (fret.data().selected && fret.data().checked) {
        if (!shouldInclude) {
          const note = notesMap[sNum][fNum];
          const noteEntry: NoteEntry = _.find(scale, (n) => n.name === note)!;
          if (noteEntry.offset === 0) {
            shouldInclude = true;
          }
        }
        if (shouldInclude) {
          ret_tabs.push([sNum, fNum]);
        }
      }
    }
  }
  return ret_tabs;
};

export default function Guitar(props: MyProps): JSX.Element {
  /**
   * Set up state
   */
  const stringsNum =
    (props.data != null ? props.data.stringsNum : undefined) || 6;
  const fretsNum = (props.data != null ? props.data.fretsNum : undefined) || 16;

  /** Local state */
  const playingFret = useState<FretType | undefined>(undefined);
  const direction = useState<"DOWN" | "UP">("DOWN");
  const originalDirection = useState<"DOWN" | "UP">("DOWN");
  const hoveredFret = useState<GuitarPos | undefined>(undefined);
  const clicked = useState(false);
  const boundingBox = useState<GuitarBoundingBox | undefined>(undefined);
  const isPlayingState = useState(false);

  /** global state */
  const startOnState = useState(globalState.startOn);
  const StartOn = startOnState.get();
  const Scale = useState(globalState.scale).get();
  const Note = useState(globalState.note).get();
  const globalPositionState = useState(globalState.position);
  const noteDivisionState = useState(globalState.noteDivision);
  const bpmState = useState(globalState.bpm);
  const globalIsPlayingState = useState(globalState.isPlaying);
  const noteNameStyleState = useState(globalState.noteNameStyle);

  /** "props" */
  const lastPosition = useState<number | undefined>(undefined);
  const lastNote = useState<string | undefined>(undefined);
  const lastScale = useState<string | undefined>(undefined);

  /**
   * willMount/didMount logic
   */
  const { notes, scale } = SCALES[Scale].get_notes(Note);

  const notesMap = generateNotes(
    stringsNum,
    fretsNum,
    props.tuning.notes,
    notes
  );

  const setPosition = (position: number) => {
    boundingBox.set(
      calculateBoundingBoxForPosition({ position, Note, Scale, notesMap })
    );
  };

  if (
    lastPosition.get() !== globalPositionState.get() ||
    lastNote.get() !== Note ||
    lastScale.get() !== Scale
  ) {
    setPosition(globalPositionState.get());
    lastPosition.set(globalPositionState.get());
    lastNote.set(Note);
    lastScale.set(Scale);
  }

  const frets = get_and_color_frets({
    stringsNum,
    fretsNum,
    notesMap,
    note: Note,
    currentPlayingFret: isPlayingState.get() ? playingFret.get() : undefined,
    boundingBox: boundingBox.get(),
    scale,
    noteNameStyle: noteNameStyleState.get(),
  });

  /**
   * Playing logic
   */
  const stopPlayScale = () => {
    isPlayingState.set(false);
    direction.set(originalDirection.get());
  };

  const countOff = () => {
    return new Promise((resolve, reject) => {
      const countInTimes = 4;
      let beatsCounted = 0;
      let timesCounted = 0;

      const tabs_to_play = get_selected_frets({
        startAtRoot: StartOn === "scaleRoot",
        frets,
        direction: direction.get(),
        notesMap,
        scale,
      });
      const firstTab = tabs_to_play[0];

      isPlayingState.set(true);

      const cb = () => {
        if (!isPlayingState.get()) {
          return;
        }

        const dividedBPM = bpmState.get() * noteDivisionState.get();
        const callbackIntervalMillis = (60 * 1000) / dividedBPM;

        const onBeat = timesCounted % noteDivisionState.get() === 0;
        if (onBeat) {
          beatsCounted += 1;
          playIntroClick();
        }

        if (timesCounted % 2 === 0) {
          playingFret.set(firstTab as FretType);
        } else {
          playingFret.set(undefined);
        }

        if (beatsCounted === 1) {
          props.displayRef.current!.innerHTML =
            "Get Ready! " + beatsCounted.toString();
        } else {
          props.displayRef.current!.innerHTML = beatsCounted.toString();
        }

        timesCounted++;

        if (timesCounted === countInTimes * noteDivisionState.get()) {
          setTimeout(resolve, callbackIntervalMillis);
        } else {
          setTimeout(cb, callbackIntervalMillis);
        }
      };

      cb();
    });
  };

  const playScale = async () => {
    let scaleDegree = 0;
    let currentDirection = direction.get();

    const frets_to_play = get_selected_frets({
      startAtRoot: false,
      frets,
      direction: currentDirection,
      notesMap,
      scale,
    });

    // if this our first time, see if we need to start the scale degree higher
    if (startOnState.get() === "scaleRoot") {
      scaleDegree = _.findIndex(frets_to_play, ([sNum, fNum]) => {
        const note = notesMap[sNum][fNum];
        return note === Note;
      })!;
    }

    const millisPerCallback =
      (60 * 1000) / (bpmState.get() * noteDivisionState.get());

    const playNote = (fret: FretType) => {
      const [sNum, fNum] = fret;
      const note = notesMap[sNum][fNum];
      const noteEntry: NoteEntry = _.find(scale, (n) => n.name === note)!;
      const halfToneOffset =
        increment === 1
          ? `+${noteEntry.offset}`
          : `-${noteEntry.offset === 0 ? 0 : 12 - noteEntry.offset}`;
      props.displayRef.current!.innerHTML = `${noteEntry.name} - ${noteEntry.scaleName} ${halfToneOffset}`;
      if (!isPlayingState.get()) {
        playingFret.set(undefined);
      } else {
        playingFret.set([sNum, fNum]);
      }
    };

    let increment = 1;
    let counter = 0;
    while (isPlayingState.get()) {
      if (counter % noteDivisionState.get() === 0) {
        playClick();
      }
      counter++;

      const timeStart = Date.now();
      const fret_to_play = frets_to_play[scaleDegree];
      playNote(fret_to_play);

      scaleDegree += increment;
      if (scaleDegree >= frets_to_play.length) {
        increment *= -1;
        scaleDegree = frets_to_play.length - 2;
      } else if (scaleDegree === -1) {
        increment *= -1;
        scaleDegree = 1;
      }

      await new Promise((resolve, reject) => {
        setTimeout(resolve, millisPerCallback - (Date.now() - timeStart));
      });
    }
  };

  /**
   * Mouse / event handlers
   */

  const onMouseEnter = useCallback(
    (guitarPos: GuitarPos) => {
      if (!clicked.get()) {
        hoveredFret.set(guitarPos);
      } else {
        boundingBox.set({
          start: live(boundingBox.get()?.start || guitarPos),
          end: live(guitarPos),
        });
      }
    },
    [clicked, hoveredFret, boundingBox]
  );

  const onMouseLeave = () => {
    hoveredFret.set(undefined);
  };

  const currentHoveredFret = hoveredFret.get();

  const onMouseUp = useCallback(() => {
    clicked.set(false);
  }, [clicked]);

  const onMouseDown = useCallback(() => {
    if (isMobile) {
      return;
    }
    if (!currentHoveredFret) {
      return;
    }
    boundingBox.set({
      start: live(currentHoveredFret),
      end: live(currentHoveredFret),
    });
    clicked.set(true);
  }, [currentHoveredFret, clicked, boundingBox]);

  if (isPlayingState.get() !== globalIsPlayingState.get()) {
    if (!boundingBox.get()) {
      globalIsPlayingState.set(false);
    } else {
      isPlayingState.get() ? stopPlayScale() : countOff().then(playScale);
    }
  }

  /**
   * Render
   */

  const firstFret =
    _.range(0, 17).find((num: number) =>
      isFretInBox({ haystack: boundingBox.get(), fNum: num })
    ) || 0;

  const lastFret =
    _.range(17, 0, -1).find((num: number) =>
      isFretInBox({ haystack: boundingBox.get(), fNum: num })
    ) || 0;

  const offsetFromHead = firstFret * props.fretWidth;
  const shapeWidth = (lastFret - firstFret) * props.fretWidth;

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          width: (fretsNum + 1) * props.fretWidth,
          margin: "auto",
          marginLeft: isMobile
            ? `calc(-${offsetFromHead}px + ( ( 100vw - ${shapeWidth}px) / 2 ) )`
            : undefined,
        }}
      >
        <div
          className="js-guitar"
          onMouseLeave={onMouseLeave}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          style={{
            backgroundColor: colors.neutral30,
            padding: "5px 0",
            margin: "5px 0",
          }}
        >
          <FretNumbers
            fretWidth={props.fretWidth}
            fretsNum={fretsNum}
            currentBoundingBox={boundingBox.get()}
          />
          <FretDots fretWidth={props.fretWidth} fretsNum={fretsNum} />

          {__range__(0, stringsNum, true).map((num) => {
            const style = num === stringsNum ? theme.shadow.default : {};

            return (
              <div style={{ ...style }} key={num}>
                <GString
                  key={`string_item_${num}`}
                  data={{
                    frets: frets[num],
                  }}
                  Fwidth={props.fretWidth}
                  Fheight={props.fretHeight}
                  boundingBox={boundingBox.get()}
                  hoveredFret={clicked.get() ? undefined : hoveredFret.get()}
                  onMouseEnter={(fret) => onMouseEnter(fret)}
                />
              </div>
            );
          })}
          <FretDots fretWidth={props.fretWidth} fretsNum={fretsNum} />
          <FretNumbers
            fretWidth={props.fretWidth}
            fretsNum={fretsNum}
            currentBoundingBox={boundingBox.get()}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}
      ></div>
    </>
  );
}

//   // document.addEventListener("keydown", (e) => {
//   //   if (e.key === " ") {
//   //     togglePlayPause();
//   //     e.preventDefault();
//   //     e.stopPropagation();
//   //   }
//   // });
// }

function live<T>(input: T): T {
  return JSON.parse(JSON.stringify(input));
}
