import { NotesMap } from "./notes";
import { SCALES } from "./scales";

export type FretData = {
  checked: boolean;
  playing: boolean;
  selected?: boolean;
  root_note?: boolean;
  is_open?: boolean;
  note: string;
  fNum: number;
  sNum: number;
  solfege?: string;
};

export type GuitarPos = { sNum: number; fNum: number };
export type GuitarBoundingBox = {
  start: GuitarPos;
  end: GuitarPos;
};

export function inGuitarPosInBox({
  needle,
  haystack,
}: {
  needle: GuitarPos;
  haystack: GuitarBoundingBox | undefined;
}): boolean {
  if (!haystack || !needle) {
    return false;
  }
  const { start, end } = haystack;
  if (!start || !end) {
    console.trace("something weird", start, end);
    return false;
  }
  return (
    needle.fNum >= Math.min(start.fNum, end.fNum) &&
    needle.fNum <= Math.max(start.fNum, end.fNum) &&
    needle.sNum >= Math.min(start.sNum, end.sNum) &&
    needle.sNum <= Math.max(start.sNum, end.sNum)
  );
}

export function isFretInBox({
  haystack,
  fNum,
}: {
  haystack: GuitarBoundingBox | undefined;
  fNum: number;
}): boolean {
  if (!haystack) {
    return false;
  }
  const { start, end } = haystack;
  return (
    fNum >= Math.min(start.fNum, end.fNum) &&
    fNum <= Math.max(start.fNum, end.fNum)
  );
}

export type FretType = [number, number];

export const calculateBoundingBoxForPosition = ({
  position,
  Note,
  Scale,
  notesMap,
}: {
  position: number;
  Note: string;
  Scale: string;
  notesMap: NotesMap;
}): GuitarBoundingBox | undefined => {
  const { positions } = SCALES[Scale];
  if (!positions) {
    console.log("no positions");
    return;
  }
  const [startOffset, endOffset] = positions[position];

  const firstString = notesMap[1];
  const firstPositionFret = firstString.indexOf(Note);

  let start = firstPositionFret + startOffset;
  let end = firstPositionFret + endOffset;

  if (start >= 12 || end > 15) {
    start %= 12;
    end %= 12;
  }

  return {
    start: { sNum: 0, fNum: start },
    end: { sNum: 6, fNum: end },
  };
};
