import React from "react";
import { useState } from "@hookstate/core";
import { noteDivision } from "./globalState";
import { setUrl } from "./helpers";

const notes = [
  {
    icon: "♩",
    value: 1,
  },
  {
    icon: "♫",
    value: 2,
  },
  {
    icon: "🎶",
    value: 3,
  },
  {
    icon: "♬",
    value: 4,
  },
] as const;

export type NoteDivision = (typeof notes)[number]["value"];

export default function DivisionButtonBar(props: { onChange: () => void }) {
  const noteDivisionState = useState(noteDivision);

  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      {notes.map((note) => {
        const isSelected = note.value === noteDivisionState.get();
        return (
          <button
            key={note.value}
            type="button"
            className={`btn btn-secondary ${isSelected ? "active" : ""}`}
            aria-pressed={isSelected}
            onClick={() => {
              noteDivisionState.set(note.value);
              setUrl({
                noteDivision: note.value,
              });
              props.onChange();
            }}
          >
            {note.icon}
          </button>
        );
      })}
    </div>
  );
}
