import Howl from "howler";
// import * as Tone from "tone";

const clickHowl = new Howl.Howl({
  src: "./resources/cowbell.mp3",
  html5: true,
});
const claveHowl = new Howl.Howl({ src: "./resources/clave.mp3", html5: true });

// const synth = new Tone.Synth().toDestination();

export function playClick() {
  claveHowl.play();
}

export function playIntroClick() {
  clickHowl.play();
}
