import { CSSProperties } from "react";

export const colors = {
  primary: "#2684FF",
  primary75: "#4C9AFF",
  primary50: "#B2D4FF",
  primary25: "#DEEBFF",

  danger: "#DE350B",
  dangerLight: "#FFBDAD",
  danger75: "rgba(255,189,173, 75%)",

  neutral0: "hsl(0, 0%, 100%)",
  neutral5: "hsl(0, 0%, 95%)",
  neutral10: "hsl(0, 0%, 90%)",
  neutral20: "hsl(0, 0%, 80%)",
  neutral30: "hsl(0, 0%, 70%)",
  neutral40: "hsl(0, 0%, 60%)",
  neutral50: "hsl(0, 0%, 50%)",
  neutral60: "hsl(0, 0%, 40%)",
  neutral70: "hsl(0, 0%, 30%)",
  neutral80: "hsl(0, 0%, 20%)",
  neutral90: "hsl(0, 0%, 10%)",

  buttonColor: "",
};

const border: CSSProperties = {
  border: colors.neutral20,
  borderRadius: 4,
  borderStyle: "solid",
  borderWidth: 1,
};

colors.buttonColor = colors.neutral5;

export const theme = {
  border,
  colors,
  defaultBottomPadding: 10,
  shadow: {
    default: {
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
  },
};

export default theme;
