import { createState } from "@hookstate/core";
import { NoteNameOptions } from "./NoteNameButtonBar";

const searchParams = new URLSearchParams(window.location.hash.substring(1));

export const noteDivision = createState(
  parseInt(searchParams.get("noteDivision") || "1")
);
export const bpm = createState(parseInt(searchParams.get("bpm") || "80"));

export const position = createState(
  parseInt(searchParams.get("position") || "0")
);
export const scale = createState(searchParams.get("scale") || "Major");
export const note = createState(searchParams.get("note") || "C");

export type StartType = "lowestNote" | "scaleRoot";
export const startOn = createState<StartType>(
  (searchParams.get("startOn") as StartType) || "lowestNote"
);

export const noteNameStyle = createState<NoteNameOptions>(
  (searchParams.get("noteNameStyle") as NoteNameOptions) || "notes"
);

export const isPlaying = createState(false);
