import React, { useRef } from "react";
import { TUNINGS } from "./../notes";

import Guitar from "../Guitar";
import Header from "../GuitarComponents/Header";
import Controls from "../GuitarComponents/Controls";

import { isMobile } from "react-device-detect";

import theme, { colors } from "../theme";

import { FooterContents, InfoFooter, InfoModal } from "../InfoComponents";

import { useState } from "@hookstate/core";

export const FretWidth = 50;
export const FretHeight = 30;

type MyProps = {};
type MyState = {
  tuning: string;
  showModal: boolean;
  position: number;
};

export default function ScalesPage(props: MyProps) {
  const displayRef = useRef<HTMLDivElement>(null);

  const showModal = useState(false);

  const handleOpenModal = () => {
    showModal.set(true);
  };

  const handleCloseModal = () => {
    showModal.set(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "100vw",
        height: "-webkit-fill-available",
        backgroundColor: colors.neutral10,
      }}
    >
      {showModal.get() && <InfoModal onClick={handleCloseModal} />}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Header ref={displayRef} />
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            backgroundColor: colors.neutral30,
            marginBottom: theme.defaultBottomPadding * 2,
          }}
        >
          <div
            style={{
              cursor: "pointer",
              overflow: isMobile ? "hidden" : "inherit",
            }}
          >
            <Guitar
              fretWidth={FretWidth}
              fretHeight={FretHeight}
              tuning={TUNINGS["Standard"]}
              displayRef={displayRef}
            />
          </div>
        </div>
        <Controls />
      </div>

      {!isMobile && (
        <div
          className="footer"
          style={{
            color: "darkslategrey",
            width: "100%",
            textAlign: "center",
            position: "absolute",
            bottom: "10px",
            fontSize: "small",
          }}
        >
          <FooterContents />
        </div>
      )}
      {isMobile && <InfoFooter onClick={handleOpenModal} />}
    </div>
  );
}
