import React from "react";
import { Stage, Layer, Line, Circle } from "react-konva";
import _ from "lodash";
import { Scale } from "./scales";
import { generateNotes, TUNINGS } from "./notes";

export default function SVGScale({
  width,
  height,
  scale,
  position,
}: {
  width: number;
  height: number;
  scale: Scale;
  position: number;
}) {
  if (!scale.positions) {
    return null;
  }

  let startingFret = scale.positions[position][0];
  let endingFret = scale.positions[position][1];

  if (startingFret < 0) {
    startingFret += 12;
    endingFret += 12;
  }

  const numStrings = 6;
  const numFrets = 5;

  const boxWidth = width * 0.85;
  const boxHeight = height * 0.85;

  const fretStrokeWidth = 1;
  const fretHeight = (boxHeight - fretStrokeWidth * 2) / (numFrets - 1);

  const stringStrokeWidth = 1;
  const stringWidth = (boxWidth - stringStrokeWidth * 2) / (numStrings - 1);

  const radius = stringWidth / 3;

  const scaleNotes = scale.get_notes(TUNINGS.Standard.notes[0]);
  const { notes } = scaleNotes;
  const scaleNoteNames = scaleNotes.scale.map((n: any) => n.name);

  const notesMap = generateNotes(6, 16, TUNINGS.Standard.notes, notes);

  return (
    <Stage width={width} height={height} style={{ pointerEvents: "none" }}>
      <Layer
        offsetX={-(width - boxWidth) / 2}
        offsetY={-(height - boxHeight) / 2}
      >
        {_.range(0, numStrings).map((i) => (
          <Line
            key={`string${i}`}
            points={[i * stringWidth, 0, i * stringWidth, boxHeight]}
            stroke={"grey"}
            width={stringStrokeWidth}
          />
        ))}

        {_.range(0, numFrets).map((i) => (
          <Line
            key={`fret${i}`}
            points={[
              0 - fretStrokeWidth,
              i * fretHeight,
              boxWidth - fretStrokeWidth,
              i * fretHeight,
            ]}
            stroke={"grey"}
            width={fretStrokeWidth}
          />
        ))}

        {_.range(1, numStrings + 1).map((stringNum) =>
          _.range(startingFret, endingFret + 1).map((fretNum) => {
            const noteAtPosition = notesMap[stringNum][fretNum];
            if (scaleNoteNames.includes(noteAtPosition)) {
              return (
                <Circle
                  key={`${stringNum}${fretNum}`}
                  fill={"black"}
                  x={(numStrings - stringNum) * stringWidth}
                  y={(fretNum - startingFret) * fretHeight}
                  radius={radius}
                />
              );
            }
            return null;
          })
        )}
      </Layer>
    </Stage>
  );
}
