import { SCALES } from "./scales";
import React from "react";
import SVGScale from "./SVGScale";
import theme, { colors } from "./theme";

export type PositionPresetProps = {
  scale: string;
  onClick: (position: number) => void;
};

export const ordinals = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
];

export const PositionPreset = (props: { scale: string; position: number }) => {
  const scaleObj = SCALES[props.scale];
  const { positions } = scaleObj;

  if (!positions) {
    return null;
  }

  const index = props.position;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <button
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          alignItems: "center",
          alignContent: "center",
          backgroundColor: colors.buttonColor,
          ...theme.border,
          padding: 5,
        }}
      >
        <h1
          style={{
            lineHeight: `${window.innerHeight / 9}px`,
            marginTop: "unset",
            marginBottom: "unset",
          }}
        >
          {ordinals[index]}
        </h1>

        <SVGScale
          width={window.innerHeight / 9}
          height={window.innerHeight / 9}
          position={index}
          scale={scaleObj}
        />
      </button>
    </div>
  );
};

const PositionPresets = (props: PositionPresetProps) => {
  const scaleObj = SCALES[props.scale];
  const { positions } = scaleObj;

  if (!positions) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {positions.map(([start, end], index) => {
        return <PositionPreset position={index} scale={props.scale} />;
      })}
    </div>
  );
};

export default PositionPresets;
