export const blFret = ({
  sNum,
  fNum,
  note,
  checked,
  playing,
  selected,
  root_note,
  is_open,
  solfege,
}: {
  sNum: number;
  fNum: number;
  note: string;
  checked: boolean;
  playing: boolean;
  selected?: boolean;
  root_note?: boolean;
  is_open?: boolean;
  solfege?: string;
}) => {
  if (!checked) {
    checked = false;
  }
  if (!playing) {
    playing = false;
  }
  if (!selected) {
    selected = false;
  }
  if (!root_note) {
    root_note = false;
  }
  if (!is_open) {
    is_open = false;
  }

  return {
    data() {
      return {
        sNum,
        fNum,
        note,
        checked,
        playing,
        selected,
        root_note,
        is_open,
        solfege,
      };
    },
    playStart() {
      return (playing = true);
    },
    playStop() {
      return (playing = false);
    },
    check() {
      return (checked = true);
    },
    uncheck() {
      return (checked = false);
    },
    select() {
      return (selected = true);
    },
    unselect() {
      return (selected = false);
    },
    set_root() {
      return (root_note = true);
    },
    set_open() {
      return (is_open = true);
    },
    set_solfege(name: string) {
      return (solfege = name);
    },
  };
};

type ReturnType<T> = T extends (...args: any[]) => infer R ? R : any;
export type BlFret = ReturnType<typeof blFret>;
