import React, { CSSProperties } from "react";
import { BlFret } from "./blFret";
import Fret from "./Fret";
import { GuitarPos, GuitarBoundingBox } from "./FretHelpers";

const GString = (props: {
  Fwidth: number;
  Fheight: number;
  data: {
    frets: Record<number, BlFret>;
  };
  hoveredFret: GuitarPos | undefined;
  boundingBox: GuitarBoundingBox | undefined;
  onMouseEnter: (guitarPos: GuitarPos) => void;
  style?: CSSProperties;
}) => {
  let fret;
  const make_fret = (fret: any) => (
    <Fret
      key={`fret_${fret.data().sNum}${fret.data().fNum}`}
      data={fret.data()}
      width={props.Fwidth}
      height={props.Fheight}
      boundingBox={props.boundingBox}
      hoveredFret={props.hoveredFret}
      onMouseEnter={props.onMouseEnter}
    />
  );

  const frets = [
    (() => {
      const result = [];
      for (let fNum in props.data.frets) {
        fret = props.data.frets[fNum];
        result.push(make_fret(fret));
      }
      return result;
    })(),
  ];
  return (
    <div
      style={{
        transform: `translateX(-15px)`,
        ...props.style,
      }}
    >
      {frets}
    </div>
  );
};

export default GString;
