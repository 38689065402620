import React from "react";
import "./css/App.css";
import "./css/bootstrap.css";
import "./css/style.css";
import ScalesPage from "./fretboard/pages/scales_page_component";

function App() {
  function appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
  }

  window.addEventListener("resize", appHeight);
  appHeight();

  return <ScalesPage />;
}

export default App;
