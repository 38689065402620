import React from "react";
import {
  FretData,
  GuitarBoundingBox,
  GuitarPos,
  inGuitarPosInBox,
} from "./FretHelpers";
import { colors } from "./theme";

const Fret = (props: {
  width: number;
  height: number;
  data: FretData;
  boundingBox: GuitarBoundingBox | undefined;
  hoveredFret: GuitarPos | undefined;
  onMouseEnter: (guitarPos: GuitarPos) => void;
}) => {
  let text = "";
  let fretClass = "fret";

  let color = undefined;

  let className = props.data.checked ? "on" : "off";
  if (props.data.checked) {
    color = colors.neutral70;
  }

  if (props.data.checked && props.data.selected) {
    color = colors.primary50;
  }

  if (props.data.root_note) {
    className = "on-selected-root";
    color = colors.danger75;
  }

  if (props.data.playing) {
    if (props.data.root_note) {
      color = colors.danger;
    } else {
      color = colors.primary;
    }
  }

  if (props.data.checked) {
    text = props.data.solfege ?? props.data.note;
  }

  if (props.data.is_open) {
    if (!props.data.checked) {
      className = `${className} open`;
    }
    text = props.data.solfege ?? props.data.note;
    fretClass = "fret-open";
  }

  if (
    props.hoveredFret?.fNum === props.data.fNum &&
    props.hoveredFret?.sNum === props.data.sNum
  ) {
    fretClass += " fret-hovered ";
  } else if (
    props.boundingBox &&
    inGuitarPosInBox({
      needle: props.data,
      haystack: props.boundingBox,
    })
  ) {
    fretClass += " fret-selected ";
  }

  return (
    <div
      className={`col-xs-1 ${fretClass} padding0`}
      style={{
        width: props.width,
        height: props.height,
      }}
      onMouseEnter={(e) => props.onMouseEnter(props.data)}
    >
      <span className="string"></span>
      <span
        className={`circleBase ${className}`}
        style={{
          background: color,
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default Fret;
