import React from "react";
import { __range__ } from "../helpers";

const SingleDotFrets = [3, 5, 7, 9, 15, 17, 19];
export default function FretDots({
  fretsNum,
  fretWidth,
}: {
  fretsNum: number;
  fretWidth: number;
}) {
  return (
    <div className="row" style={{ margin: 0 }}>
      {__range__(0, fretsNum, true).map((num) => {
        return (
          <div
            key={`fret_dot_${num}`}
            className={`col-xs-1 fretdot`}
            style={{
              textAlign: "left",
              width: fretWidth,
              right: 12,
              fontSize: "x-large",
            }}
          >
            {SingleDotFrets.includes(num) && "•"}
            {num === 12 && "••"}
          </div>
        );
      })}
    </div>
  );
}
