import React from "react";
import { useState } from "@hookstate/core";
import { noteNameStyle } from "./globalState";
import { setUrl } from "./helpers";

const options = [
  {
    icon: "DoReMi",
    value: "solfege",
  },
  {
    icon: "ABC",
    value: "notes",
  },
] as const;

export type NoteNameOptions = (typeof options)[number]["value"];

export default function NoteNameButtonBar(props: {
  onChange: (option: NoteNameOptions) => void;
}) {
  const noteNameStyleState = useState(noteNameStyle);

  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      {options.map((option) => {
        const isSelected = option.value === noteNameStyleState.get();
        return (
          <button
            key={option.value}
            type="button"
            className={`btn btn-secondary ${isSelected ? "active" : ""}`}
            aria-pressed={isSelected}
            onClick={() => {
              noteNameStyleState.set(option.value);
              setUrl({
                noteNameStyle: option.value,
              });
              props.onChange(option.value);
            }}
          >
            {option.icon}
          </button>
        );
      })}
    </div>
  );
}
