import React from "react";
import { ALL_NOTES } from "../notes";
import theme, { colors } from "../theme";
import { STEP, hSTEP, BigSTEP, SCALES } from "./../scales";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import { useState } from "@hookstate/core";
import { isPlaying, scale, note } from "../globalState";
import { setUrl } from "../helpers";
import * as _ from "lodash";

const notesOptions = ALL_NOTES.map((note) => ({ value: note, label: note }));
const scalesOptions = (() => {
  const result = [];
  for (let scale in SCALES) {
    if (!isMobile || SCALES[scale].positions) {
      result.push({ value: scale, label: SCALES[scale].desc });
    }
  }
  return result;
})();

const print_size = (size: number[]) =>
  (() => {
    const result = [];
    for (let s of Array.from(size)) {
      if (s === STEP) {
        result.push("W");
      } else if (s === hSTEP) {
        result.push("H");
      } else if (s === BigSTEP) {
        result.push("+3");
      } else {
        result.push(`+${s}`);
      }
    }

    return result;
  })().join(" - ");

const Header = React.forwardRef<HTMLDivElement>((props, ref) => {
  const isPlayingState = useState(isPlaying);
  const scaleState = useState(scale);
  const noteState = useState(note);
  const Scale = scaleState.get();
  const Note = noteState.get();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        marginBottom: theme.defaultBottomPadding,
      }}
    >
      <div
        ref={ref}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          backgroundColor: "beige",
          padding: "20px",
          fontSize: "xx-large",
          display: isPlayingState.get() ? "flex" : "none",
        }}
      >
        The banner when playing will go here
      </div>

      <div
        className="text-center"
        style={{
          margin: "0 0 10px",
          width: "90%",
          fontSize: "30px",
          fontWeight: 500,
          opacity: isPlayingState.get() ? 0 : 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: 60, marginRight: 5 }}>
            <Select
              options={notesOptions as any}
              value={notesOptions.find((o) => o.value === noteState.get())!}
              searchable={true}
              onChange={(n) => {
                note.set((n as any).value);
                setUrl({ note: (n as any).value });
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 99999999 }),
                control: (provided) => ({
                  ...provided,
                  backgroundColor: colors.buttonColor,
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...colors,
                },
              })}
            />
          </div>

          <div style={{ width: "100%", maxWidth: "300px" }}>
            <Select
              options={scalesOptions}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 99999999 }),
                control: (provided) => ({
                  ...provided,
                  backgroundColor: colors.buttonColor,
                }),
              }}
              value={scalesOptions.find((o) => o.value === Scale)!}
              onChange={(selected) => {
                scale.set((selected as any).value);
                setUrl({ scale: (selected as any).value });
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...colors,
                },
              })}
            />
          </div>
        </div>
      </div>

      <p className="text-center text-muted text-bold">
        {print_size(SCALES[Scale].size)}
      </p>
      <p className="text-center text-bold">
        {`${_.dropRight(
          SCALES[Scale].get_notes(Note).scale.map((n: any) => n.name),
          1
        ).join(" ")}`}
      </p>
    </div>
  );
});

export default Header;
