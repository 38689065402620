import React from "react";
import { GuitarBoundingBox, isFretInBox } from "../FretHelpers";
import { __range__ } from "../helpers";
import theme from "../theme";

export default function FretNumbers({
  currentBoundingBox,
  fretsNum,
  fretWidth,
}: {
  currentBoundingBox: GuitarBoundingBox | undefined;
  fretsNum: number;
  fretWidth: number;
}) {
  return (
    <div className="row" style={{ margin: 0 }}>
      {__range__(0, fretsNum, true).map((num) => {
        const isActive =
          currentBoundingBox &&
          isFretInBox({ haystack: currentBoundingBox, fNum: num });
        return (
          <div
            key={`fret_num_${num}`}
            className={`col-xs-1 fretnum`}
            style={{
              width: fretWidth,
              color: isActive
                ? theme.colors.dangerLight
                : theme.colors.neutral80,
            }}
          >
            {num}
          </div>
        );
      })}
    </div>
  );
}
