import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { useState } from "@hookstate/core";
import * as _ from "lodash";

import { ordinals, PositionPreset } from "../PositionPresets";
import CreatableInputOnly from "../CreateableInputOnly";
import DivisionButtonBar from "../DivisionButtonBar";

import {
  isPlaying,
  scale,
  StartType,
  startOn,
  bpm,
  position,
} from "../globalState";
import theme, { colors } from "../theme";
import { setUrl } from "../helpers";
import NoteNameButtonBar from "../NoteNameButtonBar";

const StartOptions: Array<{
  value: StartType;
  label: string;
}> = [
  { value: "lowestNote", label: "Lowest Note" },
  { value: "scaleRoot", label: "Scale Root" },
];

export default function Controls() {
  const isPlayingState = useState(isPlaying);
  const scaleState = useState(scale);
  const startOnState = useState(startOn);
  const positionState = useState(position);
  const bpmState = useState(bpm);

  const IsPlaying = isPlayingState.get();
  const Scale = scaleState.get();
  const StartOn = startOnState.get();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "0 5vw",
      }}
    >
      <div style={{ width: "40vw", paddingRight: 10 }}>
        <button
          className={IsPlaying ? "active" : ""}
          onClick={() => isPlaying.set(!IsPlaying)}
          style={{
            backgroundColor: colors.buttonColor,
            ...theme.border,
            height: "100%",
            width: "100%",
          }}
        >
          {IsPlaying ? (
            <FontAwesomeIcon icon={faStop} size={"4x"} />
          ) : (
            <FontAwesomeIcon icon={faPlay} size={"4x"} />
          )}
        </button>
      </div>
      {/* Guitar controls */}
      <div
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* BPM  */}
        <div
          style={{
            width: "100%",
            marginBottom: theme.defaultBottomPadding,
          }}
        >
          <CreatableInputOnly
            initialValue={bpmState.get().toString()}
            onChange={(n) => {
              const bpmValue = parseInt(n, 10);
              bpmState.set(bpmValue);
              setUrl({
                bpm: bpmValue,
              });
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginBottom: theme.defaultBottomPadding,
          }}
        >
          <DivisionButtonBar onChange={() => {}} />
        </div>

        <div
          style={{
            width: "100%",
            marginBottom: theme.defaultBottomPadding,
          }}
        >
          <NoteNameButtonBar onChange={() => {}} />
        </div>

        {/* Start option */}

        <div
          style={{
            width: "100%",
            marginBottom: theme.defaultBottomPadding,
          }}
        >
          <Select
            components={{
              SingleValue: ({ data }) => {
                return <>{data.label} First</>;
              },
            }}
            searchable={false}
            value={_.find(StartOptions, ({ value }) => value === StartOn)}
            options={StartOptions}
            onChange={(n) => {
              startOn.set((n as any).value);
              setUrl({
                startOn: (n as any).value,
              });
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 99999999 }),
              control: (provided) => ({
                ...provided,
                backgroundColor: colors.buttonColor,
              }),
            }}
          />
        </div>
        {/* position picker */}
        <div style={{ width: "100%" }}>
          <Select
            isSearchable={false}
            options={_.range(0, 5).map((n) => {
              return {
                id: n.toString(),
                label: ordinals[n] + " position",
              };
            })}
            onChange={(n) => {
              const newPos = parseInt((n as any).id, 10);
              positionState.set(newPos);
              setUrl({
                position: newPos,
              });
            }}
            value={{
              id: positionState.get().toString(),
              label: ordinals[positionState.get()] + " position",
            }}
            menuPortalTarget={document.body}
            menuPosition={"absolute"}
            menuPlacement={"top"}
            menuShouldScrollIntoView={false}
            styles={{
              menuPortal: (base) => ({
                ...base,
                top: "5vh",
                left: "5vw",
                width: "90vw",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                zIndex: 99999991,
              }),
              menu: (provided) => ({
                ...provided,
                top: 0,
                height: "90%",
                width: "unset",
                padding: 20,
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 99999992,
              }),
              menuList: (provided) => ({
                ...provided,
                maxHeight: "unset",
                maxWidth: "575px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }),
              control: (provided) => ({
                ...provided,
                backgroundColor: colors.buttonColor,
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...colors,
              },
            })}
            components={{
              Option: ({ data, innerProps }) => {
                return (
                  <div {...innerProps} key={data.id}>
                    <PositionPreset
                      scale={Scale}
                      position={parseInt(data.id, 10)}
                    />
                  </div>
                );
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
