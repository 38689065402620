import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { isMobile } from "react-device-detect";

export function FooterContents() {
  return (
    <>
      A thing by <a href="http://blackmad.com">blackmad</a>
      {isMobile ? <br /> : ", "} source on{" "}
      <a href="https://github.com/blackmad/fretboard">github</a>
      {isMobile ? <br /> : ", "}
      based on work by{" "}
      <a href="https://github.com/AlexMost/fretboard">AlexMost</a>
      {isMobile ? <br /> : ", "}
      includes sounds from <a href="https://freesound.org/">freesound.org</a>
    </>
  );
}

export const InfoModal = (props: { onClick: () => void }) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: "white",
        zIndex: 10000000,
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          border: "1px solid grey",

          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 40,
        }}
      >
        <FooterContents />
      </div>
    </div>
  );
};

export const InfoFooter = (props: { onClick: () => void }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 10,
        right: 10,
      }}
    >
      <div onClick={props.onClick}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </div>
    </div>
  );
};
